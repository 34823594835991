import {
  encodeURIComponent,
  decodeURIComponent,
  btoa,
  atob,
} from './browser';

export const isNodeJsEnvironment = () => typeof module !== 'undefined'
    && module.exports
    && Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';

export const createUrlQueryStr = (params, translations) => {
  if (!(params instanceof Object)) {
    throw new Error(translations.error.paramsShouldBeAnObjectError);
  }

  const filterNonValueParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => !!value)
  );

  if (!Object.keys(filterNonValueParams).length) return '';

  return '?' + new URLSearchParams(filterNonValueParams).toString();
};

export const validateEnvironment = (translations) => {
  const { browserEnvironmentError, browserVersionError } = translations.errors;
  if (!window || !document) {
    throw new Error(browserEnvironmentError);
  }

  if (!window.Promise || !window.sessionStorage || !window.localStorage || !window.JSON) {
    throw new Error(browserVersionError);
  }
};

/**
 * Validates configs provided on creation of widget.
 * @param {Object} config - Configuration object.
 * @param {Object} translations - Translations object.
 * @returns {null} - If there are no errors.
 */
export const validateConfig = ({
 siteUrl, noApi, maxGuests, cities, disableCities, disableGuests,
}, translations) => {
  const { paramsRequiredError, citiesRequiredError, maxGuestsRequiredError } = translations.errors;
  if (!siteUrl) {
    throw new Error(paramsRequiredError);
  }

  if (noApi === true && !disableCities && (!cities || !cities.length)) {
    throw new Error(citiesRequiredError);
  }

  if (noApi === true && !disableGuests && !maxGuests) {
    throw new Error(maxGuestsRequiredError);
  }
};

/**
 * Gets difference in days between two dates.
 * @param {Date} date1 - First date.
 * @param {Date} date2 - Second date.
 * @returns {number} - Number of days between.
 */
export const daysBetween = (date1, date2) =>
  Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));

export const daysPassedSince = (date1, date2, daysNumber) =>
  Math.abs(daysBetween(date1, date2)) > daysNumber;

export const base64Encode = str =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
    String.fromCharCode(`0x${p1}`)));

export const base64Decode = str =>
  decodeURIComponent(atob(str).split('').map(c =>
    `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

export const parseBase64StringToObject = data =>
  JSON.parse(base64Decode(data));

export const stringifyObjectToBase64 = data =>
  base64Encode(JSON.stringify(data));
