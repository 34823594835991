import moment from 'moment';// is required by lightpick
import Lightpick from '../../lib/lightpick';
import Selectr from './selectr';

import {
  getElementById,
  createElement,
  docReadyState,
  onDOMContentLoaded,
  openWindow,
  getWindow,
} from '../browser';

import {
  WIDGET_ITEM_CLASS_NAME,
  DATEPICKER_CONTAINER_CLASS_NAME,
  DATEPICKER_INPUT_WRAPPER_ID,
} from '../constants';

export const openBlankWindow = url => openWindow(url, '_blank');

export { changeLocation } from '../browser';

const insertAdjacentHTML = (ref, position, html) => {
  if (ref.insertAdjacentHTML) {
    ref.insertAdjacentHTML(position, html);
    return;
  }

  const container = ref.ownerDocument.createElementNS('http://www.w3.org/1999/xhtml', '_');
  const refParent = ref.parentNode;

  let node;
  let firstChild;
  let nextSibling;

  container.innerHTML = html;

  const firstChildRecursive = () => { node = container.firstChild; };
  const lastChildRecursive = () => { node = container.firstChild; };

  switch (position.toLowerCase()) {
    case 'beforebegin':
      while (firstChildRecursive()) {
        refParent.insertBefore(node, ref);
      }
      break;
    case 'afterbegin':
      ({ firstChild } = ref);
      while (lastChildRecursive()) {
        firstChild = ref.insertBefore(node, firstChild);
      }
      break;
    case 'beforeend':
      while (firstChildRecursive()) {
        ref.appendChild(node);
      }
      break;
    case 'afterend':
      ({ nextSibling } = ref);
      while (lastChildRecursive()) {
        nextSibling = refParent.insertBefore(node, nextSibling);
      }
      break;
    default:
      throw new Error('impossible position');
  }
};

export const getSelectTemplate = (id, placeholder) => `
  <select id="${id}">
    <option value="" selected checked disabled>${placeholder}</option>
  </select>
`;

export const getTextInputTemplate = (id, className = '', placeholder = '') =>
  `<div id="${DATEPICKER_INPUT_WRAPPER_ID}${id}"><input type="text" placeholder="${placeholder}" class="${className}" id="${id}" /></div>`;

export const getButtonTemplate = (id, placeholder, className = '') => `<button id="${id}" class="${className}">${placeholder}</button>`;

export const uniqueID = () => `_${Math.random().toString(36).substr(2, 9)}`;

export const domReady = () => {
  if (['complete', 'loaded', 'interactive'].includes(docReadyState())) {
    return Promise.resolve();
  }

  return new Promise(resolve => onDOMContentLoaded(resolve));
};

export const addClass = (id, className) => getElementById(id).classList.add(className);

export const removeClass = (id, className) => getElementById(id).classList.remove(className);

export const appendHtml = (rootElementID, htmlString) => {
  const el = getElementById(rootElementID);
  insertAdjacentHTML(el, 'beforeend', htmlString.trim());
};

export const hideElement = (id) => {
  getElementById(id).style.display = 'none';
  getElementById(id).parentElement.style.width = 0;
  getElementById(id).parentElement.style.margin = 0;
  getElementById(id).parentElement.style.padding = 0;
};

export const createOption = (val) => {
  const el = createElement('option');
  el.text = val;
  el.value = val;
  return el;
};

export const appendOption = (id, option) => {
  const el = getElementById(id);
  el.add(option);
};

export const toggleElement = (id, show) => {
  const el = getElementById(id);
  if (show === true) {
    el.style.visibility = 'initial';
  } else if (show === false) {
    el.style.visibility = 'hidden';
  }
};

export const initLinkButton = (id, cb) => {
  const el = getElementById(id);
  el.addEventListener('click', cb);
};

export const initSelect = (id, placeholder, cb) => {
  const el = getElementById(id);

  const s = new Selectr(el, {
    defaultSelected: false,
    placeholder: placeholder || 'Select an option...',
    searchable: false,
    width: 300,
  });

  s.on('selectr.change', () => cb(s.getValue()));

  return s;
};

export const initPicker = (id1, id2, rootElementID, format, lang, translations, onSelect, onClose) => new Lightpick({
  lang,
  field: getElementById(id1),
  secondField: getElementById(id2),
  singleDate: false,
  numberOfMonths: 2,
  minDate: new Date(),
  format,
  repick: true,
  parentEl: `#${rootElementID}`,
  onSelect,
  onClose,
  firstDay: 1,
  dropdowns: {
    years: {
      min: moment().year(),
      max: moment().add(10, 'years').year(),
    },
    months: true,
  },
});

export const getDatePickerContainerTemplate = contentHTML => `
  <section id="${DATEPICKER_CONTAINER_CLASS_NAME}">
    ${contentHTML}
  </section>
`;

export const getWidgetItemTemplate = (contentHTML, className = '') => `
  <section class="${WIDGET_ITEM_CLASS_NAME} ${className}">
    ${contentHTML}
  </section>
`;

/**
 * Set color of buttons.
 * @param rootElementId
 * @param {string} color - Color for buttons, fetched from website model.
 */
export const setBrandColor = (rootElementId, color) => getElementById(rootElementId).style.setProperty('--guesty-brand-primary', color);

/**
 * Decrease width by given reducers.
 * @param {number} width
 * @param {Array<Object>} reducer - Has objects with value to remove from width.
 * @returns {number} - Reduced value of width.
 * @example reduceWidth(800, reducer:
 * [{ active: true, value: 250 }, { active: true, value: 100 }]) === 450
 */
const reduceWidth = (width, reducer) =>
  reducer.reduce((prev, curr) => prev - (curr.active && curr.value), width);

/**
 * Add resize observer for responsibility.
 * @param id
 * @param className
 * @param {Object} options
 * @param {number} options.width - Width for resize.
 * @param {Array<Object>} options.reducer - Has objects with value to remove from width.
 */
export const addClassOnResizeById = (id, className, options) => new Promise((resolve) => {
  let added = null;

  // resize trigger width
  const width = reduceWidth(options.width, options.reducer);

  /**
   * Adds specific class if width of container is less than given trigger width.
   */
  const handler = () => {
    const container = getElementById(id);

    if (!container) {
      getWindow().removeEventListener('resize', this);

      return;
    }

    if (container.offsetWidth <= width && !added) {
      addClass(id, className);

      // fires initial resolve on load of document and only after class is added
      if (added === null) {
        resolve();
      }
      added = true;
    } else if (container.offsetWidth > width && added) {
      removeClass(id, className);
      if (added === null) {
        resolve();
      }
      added = false;
    } else if (added === null) {
      resolve();
    }
  };

  getWindow().addEventListener('resize', handler);
  handler();
});
